import styled from "styled-components";

export const StyledProductDetailsWrapper = styled.div`
  padding: 50px 0 75px;

  a {
    &:hover {
      text-decoration: none;
    }
  }
  .prodcutBackWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .productListWrapper {
    display: grid;
    grid-template-columns: 8fr 9fr;
    grid-gap: 40px;
    padding-top: 70px;
    .productListImg {
      background: #ffffff;
      border: 1px solid #dce2e8;
      border-radius: 12px;
      padding: 24px;
      img {
        max-width: 100%;
        border-radius: 8px;
      }
    }
  }

  @media (max-width: 965px) {
    .productListWrapper {
      padding-top: 38px;

      grid-template-columns: 1fr;
      .productListImg {
        max-width: 450px;
        padding: 16px;
      }
    }
  }

  @media (max-width: 520px) {
    .productListWrapper {
      .mobileAlign {
        display: block;

        .product_menu_div {
          width: 100%;
          margin-right: 0;
          margin-bottom: 24px;
          padding-right: 10px;

          select {
            width: 100%;
            background-position-x: right;
          }
        }
      }
    }
  }

  @media (max-width: 350px) {
    .prodcutBackWrapper {
      display: block;
      .mobileMT {
        margin-top: 24px;
      }
    }
  }
`;
