import React, { useEffect, useState } from "react";
import { css } from "styled-components";
import { navigate } from "gatsby";
import { useQuery, useLazyQuery } from "@apollo/client";

import {
  STORE_PAGE_URL,
  STORE_CART_PAGE_URL,
  HASURA_TEE_URL,
} from "../../../utils/constants";
import { GET_PRODUCT_STOCK } from "../../../graphql/queries";
import { GET_TSHIRT_ID } from "../../../graphql/queries";
import { Box, Flex, Image, Text } from "../../../globals/UIKit";
import { scrollToTop } from "../../../utils/helpers";
import { Icon } from "../../../globals/icons";
import { BackButton } from "./BackButton";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import { StyledProductDetailsWrapper } from "../styles/StyledProductDetails";
import { StyledTitle3B, StyledSubTitle2 } from "../../shared/StyledTypography";
import {
  addAndUpdateItemsInCart,
  getInitialStateForNoOfItemsToAdd,
} from "../storeHelper";
import { ProductCount } from "../CartPage/ProductCount";
import { TshirtSizeSelector } from "./TshirtSizeSelector";
import { NetworkLoader } from "../../NetworkLoader";

export const ProductTemplate = (props) => {
  const [productStock, updateProductStock] = useState(null);

  const {
    name,
    description,
    slug,
    price,
    priceId,
    imgUrl,
    printfection_item_id,
  } = props?.pageData;

  const { loading } = useQuery(GET_PRODUCT_STOCK, {
    variables: {
      price_id: priceId,
    },
    onCompleted: (data) => {
      if (
        data &&
        data?.products &&
        data.products.constructor.name === "Array" &&
        data.products.length > 0
      ) {
        return updateProductStock(data.products[0]?.stock_available);
      }
    },
  });

  const currentPathName = props?.location?.pathname;

  const isHasuraTeePage =
    currentPathName && currentPathName.startsWith(HASURA_TEE_URL);

  const [getTshirtId, { loading: tshirtIdLoading }] = useLazyQuery(
    GET_TSHIRT_ID,
    {
      variables: {
        tshirtSlug: slug,
      },
      onCompleted: (data) => {
        if (
          data &&
          data?.products &&
          data.products.constructor.name === "Array" &&
          data.products.length > 0
        ) {
          updateTshritIdsArr(data.products);
        }
      },
    }
  );

  const getDefaultStateForNoOfItemsToAdd = () => {
    if (isHasuraTeePage) {
      return 1;
    }

    return getInitialStateForNoOfItemsToAdd(priceId);
  };

  const [noOfItemsToAddInCart, updateNoOfItemsToAddInComponentState] = useState(
    getDefaultStateForNoOfItemsToAdd()
  );

  // Tshirt Specific State ******** //
  const [productSize, updateProductSize] = useState("");

  // Unisex(Men) / Women
  const [productFit, updateProductFit] = useState("");

  const [tshirtPriceId, updateTshirtPriceId] = useState("");

  const [tshirtName, updateTshirtName] = useState("");

  const [tshirtIdsArr, updateTshritIdsArr] = useState([]);

  const [tshirtId, updateTshritId] = useState(null);

  const [isTshirtSelectorError, toggleTshirtSelectorError] = useState(false);

  useEffect(() => {
    if (isHasuraTeePage) {
      getTshirtId();
    }
  }, []);

  useEffect(() => {
    if (productFit && tshirtIdsArr.length > 0) {
      const filterTshirtArr = tshirtIdsArr.filter((tshirtObj) => {
        if (
          tshirtObj &&
          tshirtObj?.apparel_size_fit &&
          tshirtObj?.apparel_size_fit.constructor.name === "String" &&
          tshirtObj.apparel_size_fit?.toLowerCase() === productFit
        ) {
          return true;
        }
      });

      if (filterTshirtArr.length > 0) {
        updateTshritId(filterTshirtArr[0]?.printfection_item_id);
      }
    }
  }, [productFit]);

  // ****************************** //

  const handleAddToCart = () => {
    if (isHasuraTeePage) {
      if (!!productFit && !!tshirtPriceId) {
        addAndUpdateItemsInCart(
          // Here using tshirtid as product id - unique for all Tshirt and sizes.
          // tshirtPriceId,
          tshirtId,
          noOfItemsToAddInCart,
          productSize,
          tshirtName ?? name,
          imgUrl,
          price,
          slug,
          tshirtPriceId,
          productStock
        );
      } else {
        return toggleTshirtSelectorError(true);
      }
    } else {
      addAndUpdateItemsInCart(
        // Here using it as product id - unique for all thisrts and sizes.
        printfection_item_id,
        noOfItemsToAddInCart,
        "",
        name,
        imgUrl,
        price,
        slug,
        priceId,
        productStock
      );
    }

    scrollToTop();

    return navigate(STORE_CART_PAGE_URL);
  };

  const handleNoOfCartItems = (noOfCartItems) => {
    if (noOfCartItems) {
      return updateNoOfItemsToAddInComponentState(noOfCartItems);
    }

    return null;
  };

  // ****************************** //

  const getAddToCartMenu = () => {
    return (
      <Box width="100%">
        <Flex
          px="32px"
          border="1px solid #DCE2E8"
          borderRadius="12px"
          justifyContent="space-between"
          height="109px"
          css={css`
            @media (max-width: 600px) {
              display: none;
            }
          `}
        >
          <Text
            color="#2C64F4"
            fontSize="30px"
            linHeight="1.25"
            fontWeight="700"
            lineHeight="1.5"
          >{`${price / 100} USD`}</Text>
          <Flex>
            <ProductCount
              noOfItemsToAdd={noOfItemsToAddInCart}
              handleNoOfCartItems={handleNoOfCartItems}
            />
            <Flex
              as="button"
              onClick={handleAddToCart}
              bg="#23303D"
              fontSize="16px"
              lineHeight="1.5"
              fontWeight={500}
              height="44px"
              width="159px"
              color="#F4FBFF"
              cursor="pointer"
              outline="none"
              border="none"
              borderRadius="8px"
              justifyContent="center"
              className="add_to_cart_btn"
              ml="38px"
            >
              <Icon
                variant="plus"
                color="white"
                size="sm"
                mr="12px"
                minWidth="12px"
                width="12px"
                maxWidth="12px"
              />
              Add to Cart
            </Flex>
          </Flex>
        </Flex>
        {/* Mobile */}
        <Box
          width="100%"
          css={css`
            @media (min-width: 601px) {
              display: none;
            }
          `}
        >
          <Flex width="100%" justifyContent="space-between" mb="40px">
            <ProductCount
              noOfItemsToAdd={noOfItemsToAddInCart}
              handleNoOfCartItems={handleNoOfCartItems}
            />
            <Text
              color="#2C64F4"
              fontSize="24px"
              linHeight="1.25"
              fontWeight="700"
              lineHeight="1.5"
            >{`${price / 100} USD`}</Text>
          </Flex>
          <Flex
            as="button"
            onClick={handleAddToCart}
            bg="#23303D"
            fontSize="16px"
            lineHeight="1.5"
            fontWeight={500}
            height="44px"
            width="100%"
            color="#F4FBFF"
            cursor="pointer"
            outline="none"
            border="none"
            borderRadius="8px"
            justifyContent="center"
            className="add_to_cart_btn"
          >
            <Icon
              variant="plus"
              color="white"
              size="sm"
              mr="12px"
              minWidth="12px"
              width="12px"
              maxWidth="12px"
            />
            Add to Cart
          </Flex>
        </Box>
      </Box>
    );
  };

  return (
    <StyledContainerWrapper>
      {(loading || tshirtIdLoading) && <NetworkLoader />}
      <StyledProductDetailsWrapper>
        <Flex width="100%" justifyContent="space-between">
          <BackButton redirectTo={STORE_PAGE_URL} />
        </Flex>
        <div className="productListWrapper">
          <div className="productListImg">
            <Image src={imgUrl} alt={name} />
          </div>
          <div>
            <StyledTitle3B paddingBottom="pb16">{name}</StyledTitle3B>
            <StyledSubTitle2 paddingBottom="pb48" fontWeight="font_400">
              {description}
            </StyledSubTitle2>
            {isHasuraTeePage && (
              <TshirtSizeSelector
                productFit={productFit}
                updateProductFit={updateProductFit}
                productSize={productSize}
                updateProductSize={updateProductSize}
                updateTshirtPriceId={updateTshirtPriceId}
                tshirtPriceId={tshirtPriceId}
                isTshirtSelectorError={isTshirtSelectorError}
                slug={slug}
                updateTshirtName={updateTshirtName}
              />
            )}
            {/* Add to Cart Menu */}
            {getAddToCartMenu()}
          </div>
        </div>
      </StyledProductDetailsWrapper>
    </StyledContainerWrapper>
  );
};
