import React from "react";
import { useQuery } from "@apollo/client";
import { css } from "styled-components/macro";

import { GET_TSHIRT_STOCK } from "../../../graphql/queries";
import { MEN_FIT_SIZE_LINK, WOMEN_FIT_SIZE_LINK } from "../storeHelper";
import { Flex, Text } from "../../../globals/UIKit";
import { NetworkLoader } from "../../NetworkLoader";

function ExternalLinkIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.22032 14.78C5.36094 14.9205 5.55157 14.9993 5.75032 14.9993C5.94907 14.9993 6.13969 14.9205 6.28032 14.78L13.5003 7.56V13.25C13.5003 13.4489 13.5793 13.6397 13.72 13.7803C13.8606 13.921 14.0514 14 14.2503 14C14.4492 14 14.64 13.921 14.7806 13.7803C14.9213 13.6397 15.0003 13.4489 15.0003 13.25V5.75C15.0003 5.55109 14.9213 5.36032 14.7806 5.21967C14.64 5.07902 14.4492 5 14.2503 5H6.75032C6.5514 5 6.36064 5.07902 6.21999 5.21967C6.07933 5.36032 6.00032 5.55109 6.00032 5.75C6.00032 5.94891 6.07933 6.13968 6.21999 6.28033C6.36064 6.42098 6.5514 6.5 6.75032 6.5H12.4403L5.22032 13.72C5.07987 13.8606 5.00098 14.0512 5.00098 14.25C5.00098 14.4488 5.07987 14.6394 5.22032 14.78Z"
        fill="#2C64F4"
      />
    </svg>
  );
}

const sizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"];

export const TshirtSizeSelector = ({
  productFit,
  updateProductFit,
  updateProductSize,
  tshirtPriceId,
  updateTshirtPriceId,
  isTshirtSelectorError,
  slug,
  updateTshirtName,
}) => {
  const { data, loading } = useQuery(GET_TSHIRT_STOCK, {
    variables: {
      slug,
    },
  });

  const isWomenFit = productFit.toLowerCase() === "women";

  const SIZE_LINK = isWomenFit ? WOMEN_FIT_SIZE_LINK : MEN_FIT_SIZE_LINK;

  const isFitSelectError = isTshirtSelectorError && productFit === "";

  const isSizeSelectError = isTshirtSelectorError && tshirtPriceId === "";

  const getFitInput = () => {
    return (
      <Flex
        className="product_menu_div"
        fontSize="16px"
        fontWeight={400}
        lineHeight="1.5"
        justifyContent="center"
        height="44px"
        border={
          isFitSelectError ? "1.5px solid #D95C5C" : "1.5px solid #E7EBEF"
        }
        color={isFitSelectError ? "#D95C5C" : "#6B859D"}
        borderRadius="4px"
        minWidth="193px"
        width="auto"
        mr="24px"
        css={css`
          font-family: "IBM Plex Sans" !important;

          select {
            font-family: "IBM Plex Sans" !important;
            padding-left: 16px;
            border: none;
            outline: none;
            background: url(https://graphql-engine-cdn.hasura.io/assets/main-site/arrow_down.svg)
              no-repeat right #fff;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-position-x: 150px;
            width: 183px;
            cursor: pointer;
            padding-right: 10px;
          }
        `}
      >
        <select
          value={productFit}
          onChange={(e) => {
            updateProductFit(e?.target?.value);
            updateTshirtPriceId("");
            updateTshirtName("");
          }}
        >
          <option disabled value="">
            Select Fit
          </option>
          <option value="unisex">Unisex</option>
          <option value="women">Women</option>
        </select>
      </Flex>
    );
  };

  const getSizeInput = () => {
    if (data && data?.products && data?.products?.length > 0) {
      const menFitArr = data?.products
        .filter(
          (stockObj) => stockObj?.apparel_size_fit.toLowerCase() === "unisex"
        )
        .sort((a, b) => {
          return (
            sizes.indexOf(a.apparel_size_label) -
            sizes.indexOf(b.apparel_size_label)
          );
        });

      const womenFitArr = data?.products
        .filter(
          (stockObj) => stockObj?.apparel_size_fit.toLowerCase() === "women"
        )
        .sort((a, b) => {
          return (
            sizes.indexOf(a.apparel_size_label) -
            sizes.indexOf(b.apparel_size_label)
          );
        });

      const currentSizeArr = isWomenFit ? womenFitArr : menFitArr;

      const getSizesDropdown = () => {
        return (
          <select
            value={tshirtPriceId}
            onChange={(e) => {
              updateTshirtPriceId(e?.target?.value);

              updateProductSize(
                e?.target.options[e.target.selectedIndex].getAttribute(
                  "data-display-size-id"
                )
              );

              updateTshirtName(
                e?.target.options[e.target.selectedIndex].getAttribute(
                  "data-display-name"
                )
              );
            }}
          >
            <option disabled value="">
              Size
            </option>
            {currentSizeArr.map((sizeObj) => (
              <option
                key={sizeObj?.priceId}
                value={sizeObj?.priceId}
                disabled={!sizeObj?.isAvailable}
                data-display-name={sizeObj?.display_name}
                data-display-size-id={sizeObj?.apparel_printfection_size_id}
              >
                {sizeObj?.apparel_size_label}
              </option>
            ))}
          </select>
        );
      };

      return (
        <Flex
          className="product_menu_div"
          fontSize="16px"
          fontWeight={400}
          justifyContent="center"
          color={isSizeSelectError ? "#D95C5C" : "#6B859D"}
          height="44px"
          border={isSizeSelectError ? "1px solid #D95C5C" : "1px solid #E7EBEF"}
          borderRadius="4px"
          minWidth="103px"
          width="auto"
          mr="24px"
          css={css`
            font-family: "IBM Plex Sans" !important;

            select {
              font-family: "IBM Plex Sans" !important;
              padding-left: 16px;
              border: none;
              outline: none;
              background: url(https://graphql-engine-cdn.hasura.io/assets/main-site/arrow_down.svg)
                no-repeat right #fff;
              -webkit-appearance: none;
              -moz-appearance: none;
              background-position-x: 70px;
              width: 103px;
              cursor: pointer;
              padding-right: 5px;
            }
          `}
        >
          {getSizesDropdown()}
        </Flex>
      );
    }

    return null;
  };

  return (
    <Flex className="mobileAlign" mb="48px">
      {loading && <NetworkLoader />}
      {getFitInput()}
      {getSizeInput()}
      <a href={SIZE_LINK} target="_blank" rel="noopener noreferrer">
        <Flex>
          <Text
            color="#2C64F4"
            fontSize="16px"
            fontWeight="500"
            lineHeight="1.5"
            mr="8px"
          >
            Size Guide
          </Text>
          <ExternalLinkIcon />
        </Flex>
      </a>
    </Flex>
  );
};
