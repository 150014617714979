import React from "react";
import { navigate } from "gatsby";
import { useQuery } from "@apollo/client";

import { NetworkLoader } from "../../components/NetworkLoader";
import { GET_SWAG_STORE_PRODUCT_LIST } from "../../graphql/queries";
import { OG_IMAGE } from "../../utils/constants";
import { ProductTemplate } from "../../components/store/ProductDetails";
import SEO from "../../components/seo";
import Layout from "../../components/store/Layout";
import CopyWriterWithSidebar from "../../components/common/CopyWriterWithSidebar";
import { ContactUsBox } from "../../components/store/StoreListing";

const Product = (props) => {
  const { loading, error, data } = useQuery(GET_SWAG_STORE_PRODUCT_LIST);

  const canonicalUrl = `https://store.hasura.io${props?.location?.pathname}`;

  if (loading) {
    return <NetworkLoader />;
  }

  if (error) {
    console.log(error);

    return <p>Something went wrong</p>;
  }

  if (data && data.products && data.products.length > 0) {
    const pageDataArr = data.products.filter(
      (productObj) => productObj.slug === props.productUrlSlug
    );

    if (pageDataArr.length > 0) {
      return (
        <Layout location={props.location}>
          <SEO
            title="Hasura Swag Store"
            description="Our favourite Hasura designs now in merch"
            meta={OG_IMAGE}
            canonicalLink={canonicalUrl}
          />
          <ProductTemplate
            pageData={pageDataArr[0]}
            location={props.location}
          />
          <ContactUsBox mb="40px" />
          <CopyWriterWithSidebar location={props.location} />
        </Layout>
      );
    }

    return navigate("/404");
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Hasura Swag Store"
        description="Our favourite Hasura designs now in merch"
        meta={OG_IMAGE}
        canonicalLink={canonicalUrl}
      />
      <p>Something went wrong.</p>
    </Layout>
  );
};

export default Product;
